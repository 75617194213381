import { Box, Link, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import dayjs from 'dayjs'
import ReactGA from 'react-ga4'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  PatientInfoForm,
  AddressForm,
  Guardian1Form,
  Guardian2Form,
} from './formDetails'
import { setFormInfo } from '../../store/formInfo'
import { setZipCode } from '../../store/zipCode'
import {
  DATE_PATTERNS,
  SUB_COLOR_LIGHT,
  CONTACT_NUMBER,
  ERROR_MESSAGE,
  DEFAULT_LANGUAGE_VALUE,
} from '../../app/constants'
import AppBar from '../../components/AppBar'
import NextButton from '../../components/NextButton'
import ViewContainer from '../../components/ViewContainer'
import BackButton from '../../components/BackButton'
import { IFormValues } from '../../app/types'
import sendSentryError from '../../utils/sentryError'
import { setServiceCenterByState } from '../../store/serviceCenterByState'
import { setPatientInfo, setPersonID } from '../../store/reschedule'
import useDialog from '../../hooks/dialog'
import Dialog from '../../components/Dialog'
import { setIdentifyPatient } from '../../store/identifyPatient'
import getAppointmentDuration from '../../api/getAppointmentDuration'
import { setAppointmentDuration } from '../../store/appointmentDuration'

const DescriptionComponent = () => (
  <NoticeBox>
    <InfoOutlinedIcon
      style={{
        fontSize: '18px',
        marginRight: '1%',
        marginLeft: '5%',
        marginTop: '0.2%',
      }}
    />
    <Box>
      <NoticeText>
        If you are trying to schedule for
        {' '}
        <b>more than one baby (multiples/twins),</b>
        {' '}
        please call
        {' '}
        <Link
          href={`tel:${CONTACT_NUMBER.value}`}
          style={{ whiteSpace: 'nowrap' }}
        >
          {CONTACT_NUMBER.description}
        </Link>
        {' '}
        to schedule an appointment.
      </NoticeText>
    </Box>
  </NoticeBox>
)

const PatientForm = () => {
  const alertHook = useDialog()
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const personInfo = useSelector((state: any) => state.formInfo.PersonDetails)
  const guardiansInfo = useSelector((state: any) => state.formInfo.Guardian)
  const {
    Name, DOB, Gender, Language, Address,
  } = personInfo || {}
  const {
    Guardian1FirstName,
    Guardian1LastName,
    Guardian1Relation,
    Guardian1Phone,
    Guardian1Email,
    Guardian2FirstName,
    Guardian2LastName,
    Guardian2Relation,
    Guardian2Phone,
    Guardian2Email,
  } = guardiansInfo || {}

  const defaultValues = {
    patientFirstName: Name?.First || '',
    patientLastName: Name?.Last || '',
    dateOfBirth: DOB ? dayjs(DOB).toDate() : null,
    gender: Gender?.Code || null,
    language: Language?.LanguageCode || DEFAULT_LANGUAGE_VALUE,
    firstLine: Address?.Line1 || '',
    secondLine: Address?.Line2 || '',
    country: Address?.Country?.Code || 'US',
    city: Address?.City || '',
    stateCode: Address?.State || '',
    zipCode: Address?.Zip || '',
    guardianFirstName: Guardian1FirstName || '',
    guardianLastName: Guardian1LastName || '',
    guardianRelation: Guardian1Relation?.Code || null,
    guardianPhoneCode: Guardian1Phone?.GuardianCode || '+1',
    guardianPhoneNumber: Guardian1Phone?.GuardianNumber || '',
    guardianEmail: Guardian1Email || '',
    guardian2FirstName: Guardian2FirstName || '',
    guardian2LastName: Guardian2LastName || '',
    guardian2Relation: Guardian2Relation?.Code || null,
    guardian2PhoneCode: Guardian2Phone?.GuardianCode || '+1',
    guardian2PhoneNumber: Guardian2Phone?.GuardianNumber || '',
    guardian2Email: Guardian2Email || '',
  }

  const {
    control, formState, handleSubmit, getValues, clearErrors,
  } = useForm<IFormValues>({
    defaultValues,
  })
  const { errors } = formState

  const handleOnClickBack = (event: any) => {
    event.preventDefault()
    window.location.href = 'https://www.cranialtech.com'
  }

  const checkDuplicatePatient = async (
    firstName: string,
    lastName: string,
    dateOfBirth: string,
    email: string,
  ): Promise<any> => {
    try {
      const baseUrl = process.env.REACT_APP_BASE_URL
      const path = '/api/check-duplicate-patient'
      const url = new URL(path, baseUrl)
      url.searchParams.append('first_name', firstName)
      url.searchParams.append('last_name', lastName)
      url.searchParams.append('date_of_birth', dateOfBirth)
      url.searchParams.append('email', email)
      const response = await axios.get(url.toString())
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const getServiceCenterByState = async (): Promise<any> => {
    try {
      const baseUrl = process.env.REACT_APP_BASE_URL
      const path = '/api/get-service-centers-states'
      const url = new URL(path, baseUrl)
      const response = await axios.get(url.toString())
      dispatch(setServiceCenterByState(response.data))
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  }

  useEffect(() => {
    getServiceCenterByState()
  }, [])

  const dispatchFormInfo = (values: IFormValues) => {
    values.dateOfBirth = dayjs(values.dateOfBirth).format(
      DATE_PATTERNS.short_date,
    )
    dispatch(setFormInfo(values))
  }

  const handleOnFinish = () => (values: IFormValues) => {
    setLoading(true)
    const firstName = values.patientFirstName
    const lastName = values.patientLastName
    const dateOfBirth = dayjs(values.dateOfBirth).format(
      DATE_PATTERNS.short_date,
    )
    const email = values.guardianEmail
    checkDuplicatePatient(firstName, lastName, dateOfBirth, email)
      .then((res) => {
        if (!res.is_duplicated) {
          dispatch(setPatientInfo({
            personID: 0,
            patientNumber: '',
          }))
          ReactGA.event({
            category: 'User',
            action: 'Patient information filled',
          })
          dispatchFormInfo(values)
          if (values.zipCode) { dispatch(setZipCode(values.zipCode)) }
          if (res.patient_information) {
            dispatch(setPatientInfo({
              patientNumber: res.patient_information.patient_number,
              personID: res.patient_information.person_id,
            }))
          }
          submitForm(values)
        } else if (res.appointment_list.is_future) {
          dispatch(setIdentifyPatient(
            {
              patientFirstName: values.patientFirstName,
              patientLastName: values.patientLastName,
              patientZipCode: res.zipcode,
              appointmentList: res.appointment_list.appointments,
            },
          ))
          dispatch(setPersonID(res.patient_information.person_id))
          alertHook.setDialog({
            title: 'Duplicate Patient',
            description: `Oops! You have already scheduled a free evaluation in our system.
                  If you would like to review the appointment details and/or make any changes, please continue here: `,
          })
          alertHook.setLinkHook(true)
        } else {
          alertHook.setDialog({
            title: 'Duplicate Patient',
            description: `Oops! Looks like you already have a head start, and have had your baby's free evaluation. 
                  Please call 844-447-5894 to schedule any other appointments.`,
          })
        }
      })
      .catch((err) => {
        dispatchFormInfo(values)
        sendSentryError(`Front: Contact form through first navigate ${err}`)
        navigate('/contact-form', { state: { messageState: ERROR_MESSAGE } })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const navigateToFindClinic = (language: string) => {
    getAppointmentDuration(
      language,
    ).then((res) => {
      dispatch(setAppointmentDuration(parseInt(res, 10)))
      navigate('/find-clinic')
    })
  }

  const submitForm = (values: any) => {
    values.country = 'US'
    values.language = values.language || DEFAULT_LANGUAGE_VALUE
    const url = new URL(
      '/api/submit-form',
      `${process.env.REACT_APP_BASE_URL}`,
    )
    const params = { slug: 'self-scheduling-form-fill' }
    url.search = new URLSearchParams(params).toString()

    const formData = new FormData()

    formData.append('input_8', values.language)
    formData.append('input_9', values.firstLine)
    formData.append('input_10', values.secondLine)
    formData.append('input_11', values.country)
    formData.append('input_12', values.city)
    formData.append('input_13', values.stateCode)
    formData.append('input_14', values.zipCode)
    formData.append('input_15', values.guardianFirstName)
    formData.append('input_16', values.guardianLastName)
    formData.append('input_18', values.guardianEmail)
    formData.append(
      'input_19',
      `(${values.guardianPhoneCode})${values.guardianPhoneNumber}`,
    )
    formData.append('input_20', values.guardian2FirstName)
    formData.append('input_21', values.guardian2LastName)
    formData.append('input_23', values.guardian2Email)
    formData.append(
      'input_24',
      `(${values.guardian2PhoneCode})${values.guardian2PhoneNumber}`,
    )
    formData.append('input_25', values.language)
    formData.append('is_submit_14', '1')
    formData.append('gform_submit', '14')
    formData.append('gform_unique_id', '')
    formData.append(
      'state_14',
      'WyJbXSIsIjYzMGRkZDk3OTc5MjJhZWUwNDU3MjRiYjg0NGI4NjljIl0=',
    )
    formData.append('gform_target_page_number_14', '0')
    formData.append('gform_source_page_number_14', '1')
    formData.append('gform_field_values', '')
    formData.append('ak_hp_textarea', '')
    formData.append('ak_js', '1689024033724')
    formData.append('version_hash', 'e044b1e9c6b37eb7d9ad70120b641f2b')
    axios.post(url.toString(), formData)
      .then((response) => {
        if (response.status === 200) {
          navigateToFindClinic(values.language)
        } else {
          sendSentryError('Front: Contact form through second navigate')
          navigate('/contact-form', { state: { messageState: ERROR_MESSAGE } })
        }
      }).catch((err) => {
        sendSentryError(`Front: Submit form error ${err}`)
        navigateToFindClinic(values.language)
      })
  }

  return (
    <ViewContainer>
      <AppBar
        description={<DescriptionComponent />}
        progress={1}
        title="Patient & Guardian Information"
      />
      <StyledBox
        component="form"
        onSubmit={handleSubmit(handleOnFinish())}
      >
        <PatientInfoForm
          control={control}
          errors={errors}
          required
        />
        <AddressForm
          clearStateErrors={clearErrors}
          control={control}
          errors={errors}
          getAddressValues={getValues}
        />
        <Guardian1Form
          control={control}
          errors={errors}
          required
        />
        <Guardian2Form
          control={control}
          errors={errors}
        />
        <NextButton
          isLoading={isLoading}
          secondary
          text="Next"
        />
        <BackButton onClick={handleOnClickBack} />
      </StyledBox>

      <StyledMessage>
        *We strive to provide the best patient experience, we may reach out to
        the contact information provided to ensure your appointment is
        successfully scheduled.
      </StyledMessage>
      <Dialog
        dialogHook={alertHook}
      />
    </ViewContainer>
  )
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
`

const NoticeBox = styled(Box)`
  padding-top: 2%;
  padding-bottom: 2%;
  background-color: #D9D9D94D;
  display: flex;
  flex-direction: row;
  text-align: left;
`

const NoticeText = styled(Typography)`
  white-space: initial;
  margin-right: 3%;
`

const StyledMessage = styled(Typography)`
  color: ${SUB_COLOR_LIGHT};
  font-size: 14px;
  margin-top: 15px;
`

export default PatientForm
