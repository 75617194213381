import { Box } from '@mui/material'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import AppBar from '../../components/AppBar'
import clearStorage from '../../app/clear-storage'
import { CONTACT_NUMBER, ERROR_MESSAGE } from '../../app/constants'
import { IContactFormValues } from '../../app/types'
import NextButton from '../../components/NextButton'
import ViewContainer from '../../components/ViewContainer'
import BackButton from '../../components/BackButton'
import ContactFormDetails from './formDetails'
import useDialog from '../../hooks/dialog'
import Dialog from '../../components/Dialog'

const PatientForm = () => {
  const alertHook = useDialog()
  const [description, setDescription] = useState(ERROR_MESSAGE)
  const dispatch = useDispatch()
  const { state } = useLocation()
  const navigate = useNavigate()
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false)
  const Guardian = useSelector((s: any) => s.formInfo.Guardian)
  const PersonDetails = useSelector((s: any) => s.formInfo.PersonDetails)

  const getFullName = () => {
    if (Guardian) {
      const firstName = Guardian.Guardian1FirstName
      const lastName = Guardian.Guardian1LastName
      return `${firstName} ${lastName}`
    }
    return ''
  }

  const defaultValues = {
    stateCode: PersonDetails?.Address.State || '',
    clinicLocation: '',
    fullName: getFullName(),
    email: Guardian?.Guardian1Email || '',
    phoneNumber: Guardian?.Guardian1Phone.GuardianNumber || '',
    message: '',
  }

  const {
    control, formState, handleSubmit, resetField, watch,
  } = useForm<IContactFormValues>({ defaultValues })
  const { errors } = formState
  const watchState = watch('stateCode')

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'stateCode') {
        // Reset clinic location input when state changes.
        resetField('clinicLocation')
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  const handleOnClickBack = (event: any) => {
    event.preventDefault()
    navigate('/')
  }

  useEffect(() => {
    if (state) {
      setDescription(state.messageState)
    }
  }, [])

  const handleOnFinish = () => async (values: IContactFormValues) => {
    setLoadingRequest(true)
    const url = new URL('/api/submit-form/', process.env.REACT_APP_BASE_URL)
    url.searchParams.append(
      'slug',
      'schedule-a-free-evaluation-form-handler-prd',
    )
    const {
      stateCode, clinicLocation, fullName, email, phoneNumber, message,
    } = values
    const params = new URLSearchParams({
      input_9: stateCode,
      input_27: clinicLocation,
      input_1: fullName,
      input_2: email,
      input_3: phoneNumber,
      input_4: message,
      input_28: '',
      is_submit_6: '1',
      gform_submit: '6',
      gform_unique_id: '',
      gform_target_page_number_6: '0',
      gform_source_page_number_6: '1',
      gform_field_values: '',
      version_hash: '57d94ffca7e983de0129e11e56c3655e',
    })
    try {
      const response = await axios.post(url.toString(), params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Access-Control-Allow-Origin': `${process.env.REACT_APP_BASE_URL}`,
          mode: 'cors',
        },
      })
      if (response.status === 200) {
        clearStorage(dispatch)
        window.location.replace(
          'https://www.cranialtech.com/thank-you-evaluation/',
        )
      } else {
        throw Error('request failed')
      }
    } catch (e) {
      setLoadingRequest(false)
      alertHook.setDialog({
        title: 'Error',
        description: `Sorry, there was an error with the system, please call ${CONTACT_NUMBER.other}.`,
        buttonHandler: () => {
          navigate('/')
        },
      })
    }
  }

  return (
    <ViewContainer>
      <AppBar
        description={description}
        progress={0}
        title="Request an Appointment"
      />
      <StyledBox
        component="form"
        onSubmit={handleSubmit(handleOnFinish())}
      >
        <ContactFormDetails
          control={control}
          errors={errors}
          required
          stateCode={watchState}
        />
        <NextButton
          isLoading={loadingRequest}
          secondary
          text="Submit"
        />
        <BackButton onClick={handleOnClickBack} />
      </StyledBox>
      <Dialog
        buttonText="Contact Us"
        dialogHook={alertHook}
      />
    </ViewContainer>
  )
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
`

export default PatientForm
