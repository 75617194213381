import { Box } from '@mui/material'
import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import dayjs from 'dayjs'
import PatientInfoForm from './formDetails'
import { setIdentifyPatient } from '../../store/identifyPatient'
import AppBar from '../../components/AppBar'
import NextButton from '../../components/NextButton'
import ViewContainer from '../../components/ViewContainer'
import BackButton from '../../components/BackButton'
import { IIdentifyPatientValues } from '../../app/types'
import getPatientAppointments from '../../api/getPatientAppointments'
import { setPersonID } from '../../store/reschedule'
import useDialog from '../../hooks/dialog'
import Dialog from '../../components/Dialog'
import { DATE_PATTERNS } from '../../app/constants'

const DescriptionTitle = () => (
  <Box component="span">
    Please provide the following information so we can find your existing appointment.
  </Box>
)

const IdentifyPatient = () => {
  const alertHook = useDialog()
  const [isLoading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const identifyPatient = useSelector((state: any) => state.identifyPatient)

  const defaultValues = {
    patientFirstName: identifyPatient.patientFirstName || '',
    patientLastName: identifyPatient.patientLastName || '',
  }

  const {
    control, formState, handleSubmit,
  } = useForm<IIdentifyPatientValues>({
    defaultValues,
  })
  const { errors } = formState

  const handleOnClickBack = (event: any) => {
    event.preventDefault()
    navigate('/')
  }

  const handleOnFinish = () => (values: IIdentifyPatientValues) => {
    setLoading(true)
    getPatientAppointments(
      {
        ...values,
        dateOfBirth: dayjs(values.dateOfBirth).format(DATE_PATTERNS.short_date),
      },
    ).then((res) => {
      dispatch(setIdentifyPatient(
        {
          ...values,
          patientZipCode: res.zipcode,
          appointmentList: res.appointment_list,
        },
      ))
      dispatch(setPersonID(res.person_id))
      navigate('/scheduled-appointment-information')
    }).catch(() => {
      alertHook.setDialog({
        title: "We're Sorry",
        description: `We could not find an appointment for ${values.patientFirstName} ${values.patientLastName}`,
        buttonHandler: () => {
          navigate('/contact-form')
          alertHook.closeDialog()
        },
      })
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <ViewContainer>
      <AppBar
        description={<DescriptionTitle />}
        progress={1}
        title="Cancel or Reschedule an Appointment"
      />
      <StyledBox
        component="form"
        onSubmit={handleSubmit(handleOnFinish())}
      >
        <PatientInfoForm
          control={control}
          errors={errors}
          required
        />

        <NextButton
          isLoading={isLoading}
          secondary
          text="Search"
        />
        <BackButton onClick={handleOnClickBack} />
      </StyledBox>
      <Dialog
        buttonText="Contact Us"
        dialogHook={alertHook}
      />
    </ViewContainer>
  )
}

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
`

export default IdentifyPatient
